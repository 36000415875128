@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Raleway:wght@500&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Raleway:wght@500&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Raleway:wght@500&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Irish+Grover&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Playfair+Display:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Raleway:wght@500&family=Roboto:wght@100;300;900&display=swap');
$main-color: #dca486;
$secondary-color: #efe5db;
$text-color: #555c66;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;

  .wrapper {
    min-height: calc(100vh - 245px);
  }
  .container {
    padding: 0px 86px;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .col-12 {
    width: 100%;
  }
  .col-10 {
    width: 85%;
  }
  .col-11 {
    width: 90%;
  }
  .col-9 {
    width: 75%;
  }

  .col-8 {
    width: 67%;
  }

  .col-7 {
    width: 60%;
  }

  .col-6 {
    width: 50%;
  }

  .col-5 {
    width: 40%;
  }

  .col-4 {
    width: 33%;
  }

  .col-3 {
    width: 25%;
  }
  .col-2 {
    width: 15%;
  }
  a {
    text-decoration: none;
    color: #fff;
  }

  h1 {
    font-size: 44px;
  }
  p {
    font-size: 18px;
  }
  h2 {
    font-size: 36px;
  }
}

.navbarBrand {
  .brand {
    background-color: $main-color;
    padding: 0px 18px;
    border-radius: 25px 0px;
    a {
      text-decoration: none;
      color: #fff !important;
      font-weight: bold;
      font-size: 24px !important;
    }
  }
}

.btn {
  background-color: $main-color;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem;
  font-weight: 400;
  padding: 15px 52px;
  display: block;
  transition: all 0.5s ease;
  box-shadow: 0 0 5px 0 $main-color;
  &:hover {
    background-color: #fff;
    color: $main-color;
  }
}

#Navbar {
  padding: 16px 0px;
  position: sticky;
  top: 0;
  background-color: #fff !important;
  z-index: 10;
  // overflow: hidden;
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navItem {
      a {
        font-size: 18px;
        font-weight: 500;
        line-height: 40px;
        color: $text-color;
        text-decoration: none;
        padding: 8px 16px;
        margin: 0px 16px;
        &:hover {
          background-color: $main-color;
          color: #fff;
          border-radius: 5px;
          transition: all 0.5s;
        }
      }
    }
  }
  .row {
    align-items: center;
    justify-content: space-between;
  }
  .sign {
    background-color: $main-color;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s linear;
    display: flex;
    div {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      transition: all 0.3s ease;
      margin: 0px 6px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      color: #fff;
    }
    &:hover {
      box-shadow: 0px 0px 5px 0px $main-color;
    }
  }
  .active {
    background-color: $main-color;
    color: #fff !important;
    border-radius: 5px;
  }
}

.burger {
  .icon {
    width: 35px;
    height: 35px;
    color: $text-color;
  }

  display: none;
}

#Header {
  padding: 0px;
  height: 50vw;
  .imgCollection {
    position: relative;
    width: 100%;
    height: 100%;
    .first {
      position: absolute;
      top: 2vw;
      left: 10vw;
      width: 12vw;
      height: 12vw;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 5px 5px 25px 0 rgb(0 0 0 / 20%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .second {
      position: absolute;
      top: 1vw;
      left: 22vw;
      width: 30vw;
      height: 30vw;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 5px 5px 25px 0 rgb(0 0 0 / 20%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .third {
      position: absolute;
      top: 1vw;
      right: 1vw;
      width: 18vw;
      height: 18vw;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .fourth {
      position: absolute;
      top: 16vw;
      left: 20%;
      width: 15vw;
      height: 15vw;
      border-radius: 50%;
      overflow: hidden;
      border: 1.5vw solid #fff;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      position: absolute;
      right: 0vw;
      top: 32vw;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h1 {
        text-transform: uppercase;
        text-align: right;
        font-size: 54px;
        font-family: 'Playfair Display', sans-serif;
      }
      p {
        text-align: right;
        margin: 16px 0px;
      }
    }
  }
}

#Information {
  background: linear-gradient(white 65%, $secondary-color 35%);
  .line {
    border: 1px solid $secondary-color;
    width: 100%;
    margin: 0px 0px 32px;
  }
  .card {
    margin: 32px 10px 0px;
    background-color: #fff;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);
    padding: 16px;
    text-align: center;
    min-height: 90%;
    margin-top: 32px;
    .cardHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      .headImg {
        width: 100px;
        height: 100px;
        // border-radius: 50%;
        overflow: hidden;
        // background-color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .cardBody {
      margin-top: 16px;
      h2 {
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size: 1.25rem;
      }
      p {
        margin-top: 16px;
        color: $text-color;
      }
    }
  }
}

#About {
  background: linear-gradient($secondary-color 60%, white 40%);
  padding: 32px 0px;
  .row {
    justify-content: center;
    margin-top: 150px;
  }
  .card {
    background-color: #fff;
    padding: 32px;
    margin-top: 200px;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);
    .cardHeader {
      font-size: 4.5rem;
      letter-spacing: normal;
      font-weight: 700;
      text-transform: none;
      font-family: 'Playfair Display', serif;
      margin: 16px 0px;
      text-align: center;
    }
    .cardBody {
      p {
        font-size: 1.25rem;
        color: $text-color;
        margin-top: 16px;
        margin-left: 16px;
      }
    }
    .cardFooter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
    }
  }
  .circle {
    width: 37vw;
    height: 37vw;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 20%);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

#Feature {
  padding: 64px 0px;
  h1 {
    text-align: center;
    text-transform: uppercase;
    margin: 16px 0px;
  }
  .row {
    justify-content: space-around;
    margin-top: 32px;
  }
  .col-5 {
    h3 {
      letter-spacing: 2px;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 16px;
    }
    p {
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
      line-height: 2;
      color: $text-color;
    }
  }
  .col-4 {
    justify-content: flex-end;
    .circle {
      width: 340px;
      height: 340px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
#Tarif {
  padding: 32px 0px;
  background-color: $secondary-color;
  ul {
    list-style-type: none;
  }
  .row {
    justify-content: space-around;
  }
  h1 {
    text-align: center;
    font-size: 44px;
    color: #111;
    letter-spacing: normal;
    font-weight: 400;
    text-transform: none;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
  }
  p {
    text-align: center;
    margin-top: 16px;
  }
  .card {
    margin: 32px 0px 0px;
    border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: rgba(197, 200, 202, 0.5) 0px 8px 24px;
    }
    padding: 32px;
    // background: linear-gradient(#fff 50%, $secondary-color 50%);
    background-color: #fff;

    .cardHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        color: #111;
        text-align: center;
        font-size: 44px;
        color: #111;
        letter-spacing: normal;
        font-weight: 400;
        text-transform: none;
        font-family: 'Playfair Display', serif;
      }
      .title {
        background-color: $text-color;
        color: #fff;
        font-size: 24px;
        margin-top: 16px;
        border-radius: 16px;
      }
      h3 {
        margin: 16px 0px 0px;
        color: #111;
        font-size: 24px;
      }
    }
    .cardBody {
      padding: 16px 0px;

      ul {
        margin-left: 16px;
        li {
          margin-top: 16px;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Montserrat', sans-serif;
          line-height: 2;
          color: $text-color;
        }
      }
    }
  }
}

#IntroVideo {
  background: linear-gradient(white 20%, $secondary-color 80%);
  padding: 32px 0px;
  .row {
    justify-content: space-around;
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    .circle {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h3 {
      letter-spacing: normal;
      font-weight: 400 !important;
      text-transform: none;
      font-family: 'Playfair Display', serif;
      font-size: 2.25rem;
      line-height: 1.1;
      margin: 16px 0px;
      text-align: center;
    }
    ul {
      list-style-type: none;
      li {
        line-height: 2;
        font-family: 'Raleway', sans-serif;
        color: #111;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
}

#Footer {
  padding: 32px 0px;
  background-color: $text-color;
  height: 100%;
  color: #fff;
  .navbarBrand {
    width: 70%;
    margin-bottom: 16px;
  }
  .line {
    border: 1px solid #ffffff78;
    width: 100%;
    margin: 16px 0px 32px 0px;
  }
  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bgLink {
      width: 35px;
      height: 35px;
      border-radius: 8px;
      background-color: $secondary-color;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #000;
      &:hover {
        background-color: $main-color;
        color: #fff;
        transition: all 0.4s;
      }
    }
  }
  .card {
    margin: 0px 16px;

    h4 {
      padding: 20px 0px;
      font-weight: normal;
      font-size: 16px;
    }
    h2 {
      font-size: 18px;
      color: #ffffff78;
      font-weight: normal;
      text-transform: uppercase;
    }
    p,
    a {
      cursor: pointer;
      font-size: 16px;
      padding-top: 10px;
      color: #cbcbcb;
      transition: all 0.3s ease;
      scroll-behavior: smooth !important;
      &:hover {
        color: #fff;
      }
    }
  }
  .reserved {
    .row {
      justify-content: flex-end;
      color: #b1b1b1;
      margin: 16px 0px;
    }
  }
}

#Lesson {
  padding: 16px 0px;
  .row {
    justify-content: center;
  }
  .col-9 {
    .card {
      border: 1px solid #b1b1b1;
      padding: 16px;
      border-radius: 10px;
    }
  }
  .cardLesson {
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 20px;
    margin: 0px 0px 16px 16px;
    cursor: pointer;
    h3 {
      font-weight: normal;
      color: $text-color;
      display: flex;
      align-items: center;
      div {
        margin-left: 10px;
      }
    }
  }
  .tabs {
    margin-top: 16px;
  }
}

@keyframes modalFade {
  from {
    transform: translateY(-80%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center !important;
  }
  label {
    padding: 16px 0px 10px;
    font-size: 16px;
  }
  input {
    border-radius: 16px;
    padding: 6px 15px;
    border: 1px solid #fafafa;
    background-color: #fafafa;
    font-size: 16px;
    &:focus {
      border: 2px solid $main-color !important;
    }
  }
  button {
    background-color: $main-color;
    border: none;
    border-radius: 16px;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    margin: 20px 0px 16px;
    cursor: pointer;
  }
  p {
    text-align: center;
    color: $text-color;
    font-size: 18px;
    margin-top: 16px;
  }
  .link {
    background-color: transparent;
    border: none;
    color: $main-color;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
  }
}
.ReactModalPortal {
  z-index: 10 !important;
}

.loading {
  margin: 0 auto;
}
.logout {
  // display: flex;
  // flex-direction: column;
  h2 {
    text-align: center !important;
  }
  .buttonLogout {
    background-color: $main-color;
    border: none;
    border-radius: 16px;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    margin: 20px 0px 16px;
    cursor: pointer;
  }
  p {
    text-align: center;
    color: $text-color;
    font-size: 18px;
    margin-top: 16px;
  }
  .link {
    background-color: transparent;
    border: none;
    color: $main-color;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
  }
}

#Courseoverview {
  .layer {
    padding: 32px 0px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../../public/images/3.JPG');

    background-size: contain;
    .main {
      background-color: rgba(197, 200, 202, 0.1);
      padding: 32px;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h1 {
      color: #fff;
      text-align: center;
      margin: 16px 0px;
    }
    .link {
      position: sticky;
      top: 70px;
      width: 70%;
      z-index: 10;
    }
    a {
      text-align: center;
      margin-bottom: 16px;
    }

    .content {
      p {
        background-color: #fff;
        padding: 64px;
        border-radius: 15px;
        padding: 32px;
        // line-height: ;
      }
      h3 {
        text-align: center;
        margin-bottom: 16px;
      }
      .row {
        // align-items: center;
        padding: 32px 0px;
        justify-content: space-between;
      }
      video {
        background-color: #000 !important;
      }
      audio {
        margin-top: 16px;
      }
      video,
      img {
        border-radius: 15px;
        height: 400px;
      }
    }
  }
}

.buysection {
  line-height: 2rem;
  h3 {
    font-weight: normal;
    line-height: 1.5rem;
    margin-top: 16px;
  }
  h4 {
    font-size: 22px;

    margin-top: 16px;
  }
  .btn {
    background-color: rgb(88, 155, 255);
    box-shadow: none;
    border-radius: 10px;
  }
}
