@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Poppins:ital,wght@0,200;0,300;1,200&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Poppins:ital,wght@0,200;0,300;1,200&family=Roboto:wght@100;300;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@700&family=Josefin+Sans:wght@700&family=Montserrat:wght@500&family=Poppins:ital,wght@0,200;0,300;1,200&family=Roboto:wght@100;300;900&display=swap');
$main-color: #41ae8d;
$secondary-color: #fafafa;
$text-color: #374557;

// **********BODY**************
body {
  @media (min-width: 1550px) {
    .container {
      padding: 0px 300px !important;
    }
  }
  @media (max-width: 1550px) {
    h1 {
      font-size: 44px !important;
    }
  }
  @media (max-width: 750px) {
    .container {
      padding: 0px 3% !important;
    }
  }
  @media (max-width: 720px) {
    h1 {
      font-size: 36px !important;
    }
    li,
    p,
    a {
      font-size: 16px !important;
    }
    h2 {
      font-size: 24px !important;
    }
    .btn {
      padding: 10px 24px !important;
    }
  }
  @media (max-width: 350px) {
    h1 {
      font-size: 24px !important;
    }
    li,
    a,
    p {
      font-size: 14px !important;
    }
    h2 {
      font-size: 18px !important;
    }
  }
}

// ***********NAVBAR***********
#Navbar {
  @media (max-width: 500px) {
    .burger {
      float: right;
      display: block !important;
      cursor: pointer;
    }
    .responsive {
      position: relative;
    }
    .nav {
      display: none !important;
    }
    .responsive .nav {
      display: block !important;

      width: 100%;
      animation-name: openNavbar;
      animation-duration: 0.5s;
      background-color: #fff;
      padding: 32px;
      position: absolute;
      top: 50px;
      right: 0;
      text-align: center;
    }
    @keyframes openNavbar {
      from {
        position: absolute;
        top: 0;
        right: 0;
      }
      to {
        position: absolute;
        top: 50px;
        right: 0px;
      }
    }

    .responsive .sign {
      background-color: #fff !important;

      div,
      span {
        color: $text-color !important;
      }
    }
  }
}
// *********HEADER************
#Header {
  @media (max-width: 1550px) {
    .third {
      right: 15vw !important;
    }
  }
  @media (max-width: 1400px) {
    .third {
      right: 17vw !important;
    }
  }

  @media (max-width: 1200px) {
    height: 60vw !important;
  }
  @media (max-width: 950px) {
    height: 70vw !important;
    .text {
      width: 100% !important;
    }
  }
  @media (max-width: 600px) {
    height: 80vw !important;
  }

  @media (max-width: 500px) {
    height: 120vw !important;
    h1 {
      font-size: 24px !important;
    }
    .first {
      width: 25vw !important;
      height: 25vw !important;
      left: 0vw !important;
    }
    .second {
      width: 60vw !important;
      height: 60vw !important;
    }
    .third {
      width: 30vw !important;
      height: 30vw !important;
      right: 0vw !important;
    }
    .fourth {
      width: 25vw !important;
      height: 25vw !important;
      top: 40vw !important;
      left: 10% !important;
    }
    .text {
      top: 65vw !important;
    }
  }
}

// *******INFORMATION************
#Information {
  @media (max-width: 1000px) {
    .col-3 {
      width: 33%;
    }
  }
  @media (max-width: 900px) {
    .col-3 {
      width: 50%;
    }
  }
  @media (max-width: 500px) {
    .col-3 {
      width: 100%;
    }
  }
}

// **********ABOUT*************
#About {
  @media (max-width: 1200px) {
    .card {
      margin-top: 0px !important;
    }
    .row {
      margin-top: 0px !important;
    }
  }
  @media (max-width: 900px) {
    .col-5,
    .col-6 {
      width: 100%;
    }
    .col-6 {
      display: flex;
      align-items: center;
      justify-content: center;
      .circle {
        margin-top: 16px;
        width: 50vw;
        height: 50vw;
      }
    }
  }
  @media (max-width: 500px) {
    .circle {
      width: 90vw !important;
      height: 90vw !important;
    }
  }
}

// **********FUTURE***************
#Feature {
  @media (max-width: 1000px) {
    .col-5,
    .col-4 {
      width: 50%;
      display: flex;
      justify-content: center !important;
      text-align: center;
      margin-top: 16px;
    }
  }
  @media (max-width: 800px) {
    .col-5,
    .col-4 {
      width: 100%;
    }
  }
}

// ***********INTROVIDEO*************
#IntroVideo {
  @media (max-width: 1400px) {
    .col-5,
    .col-4 {
      width: 50%;
    }
    .card {
      margin-left: 16px;
    }
  }
  @media (max-width: 1000px) {
    .col-5,
    .col-4 {
      width: 100%;
    }
    .card {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
}
//***********FOOTER****************
#Footer {
  @media (max-width: 920px) {
    .col-3 {
      width: 33%;
    }
    .navb {
      width: 100% !important;
      margin-bottom: 32px;
      .navbarBrand {
        width: 30%;
      }
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .col-6 {
      width: 30%;
    }
  }
  @media (max-width: 750px) {
    .col-3 {
      width: 50%;
      margin-top: 32px;
    }
    h2 {
      font-size: 18px !important;
    }
  }
  @media (max-width: 500px) {
    .navb {
      width: 100%;
      .card {
        align-items: flex-start !important;
      }
    }
    .col-6 {
      width: 50%;
    }
    .navbarBrand {
      width: 50% !important;
    }
    .reserved {
      .row {
        margin-top: 32px !important;
        justify-content: center !important;
      }
    }
  }
}

// ***********TARIFF******************

#Tarif {
  @media (max-width: 900px) {
    .col-5 {
      width: 100%;
    }
    .card {
      padding: 16px !important;
      li {
        font-size: 18px !important;
      }
    }
    .btn {
      background-color: #e06c2e;
      font-size: 18px !important;
    }
  }
}

//**************LESSON*****************

#Lesson {
  @media (max-width: 920px) {
    .col-8 {
      width: 100% !important;
    }
    .col-4 {
      display: none;
    }
    h3 {
      font-size: 16px !important;
    }
    .cardLesson {
      margin-left: 0px !important;
    }
  }
}

//************PROFILE****************
#Profile {
  @media (max-width: 900px) {
    .row {
      justify-content: center;
    }
    .col-5 {
      width: 90%;
    }
    .col-6 {
      width: 90%;
    }
  }
}
#Courseoverview {
  @media (max-width: 920px) {
    .col-5,
    .col-6 {
      width: 100%;
      margin-bottom: 16px;
    }
    .col-4,
    .col-7 {
      width: 50%;
      margin-bottom: 16px;
    }
    .row {
      padding: 0 !important;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: 720px) {
    .col-4,
    .col-7 {
      width: 100%;
    }
    .row {
      justify-content: center !important;
    }
    .main {
      padding: 0 !important;
    }
    .link {
      width: 100% !important;
    }
  }
  @media (max-width: 370px) {
    img,
    video,
    audio {
      width: 80% !important;
    }
  }
}
// *********MODAL***************

.ReactModal__Content {
  @media (max-width: 1050px) {
    width: 80% !important;
    inset: 15% auto auto 10% !important;
  }
}
.ReactModal__Content {
  @media (max-width: 720px) {
    .form {
      .link {
        font-size: 14px;
      }
    }
  }
}
